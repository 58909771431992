<template>
  <section class="portal">
    <div class="grid grid-pad">
      <div class="col-1-1">
        <h1 class="page-title">{{ $t("Welcome to BL Care") }}</h1>
      </div>

      <div class="portal-choices flex col-1-1 no-p">
        <div class="col-1-2 xs-col-1-1 flex">
          <div class="choice-item flex flex-column justify-space-between bg-blue">
            <div class="text">
              <h5 class="subtitle">
                {{ $t("Having a problem? We can help!") }}
              </h5>
              <h3 class="title">{{ $t("Create a ticket") }}</h3>
            </div>
            <div>
              <router-link
                :to="{ name: 'Portal-CreateTicket' }"
                class="btn btn-secondary"
                >{{ $t("Create a ticket") }}</router-link
              >
            </div>
          </div>
        </div>

        <div class="col-1-2 xs-col-1-1 flex">
          <div class="choice-item flex flex-column justify-space-between bg-blue">
            <div class="text">
              <h5 class="subtitle">
                {{ $t("Want an update on your ticket?") }}
              </h5>
              <h3 class="title">{{ $t("Request ticket status") }}</h3>
            </div>
            <div>
              <router-link
                :to="{ name: 'Portal-RequestStatus' }"
                class="btn btn-secondary"
                >{{ $t("Request status") }}</router-link
              >
            </div>
          </div>
        </div>

        <!-- <div class="col-1-3 sm-col-1-2 xs-col-1-1 flex">
          <div class="choice-item flex flex-column justify-space-between bg-blue">
            <div class="text">
              <h5 class="subtitle">
                {{ $t("We might already have an answer!") }}
              </h5>
              <h3 class="title">{{ $t("Frequently asked questions") }}</h3>
            </div>
            <div>
              <a href="https://brandloyalty-customersupport.freshdesk.com/en/support/solutions" target="_blank" class="btn btn-secondary">{{
                $t("View Q&A")
              }}</a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Portal',
  components: {},
  data () {
    return {}
  },
  beforeCreate () {},
  mounted () {},
  computed: {},
  methods: {},
  watch: {}
}
</script>
